"use client"

import CinemaSelector from "src/components/cinemaselector/CinemaSelector";
import { gql } from 'graphql-request';
import { useFilminfo } from 'src/lib/client/useFilminfo';
import { useLocationContext } from 'src/lib/contexts/LocationContext';
import { Cinema } from 'src/lib/movieinfo/movieinfotypes';
import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';

//#region [Other]
export type SelectedCinema = {
    selectedCinemas: string[] | null;
    setSelectedCinemas: (cinemas: string[] | null) => void; // overrides all selections
    addSelectedCinema: (cinema: string) => void;
    removeSelectedCinema: (cinema: string) => void;
    availableCinemas: Cinema[];
    setAvailableCinemas: (cinemas: Cinema[]) => void;
    showSelectCinemaDialog: boolean,
    setShowSelectCinemaDialog: (show: boolean) => void,
    isReady: boolean | null;
}
//#endregion


//#region [Other]
const SelectedCinemaContext = createContext<SelectedCinema>({
    selectedCinemas: null,
    setSelectedCinemas: (cinema: string[] | null) => { },
    addSelectedCinema: (cinema: string) => { },
    removeSelectedCinema: (cinema: string) => { },
    availableCinemas: [],
    setAvailableCinemas: (cinemas: Cinema[]) => { },
    showSelectCinemaDialog: false,
    setShowSelectCinemaDialog: (show) => { },
    isReady: null
});
//#endregion

//#region [Props]
type SelectedCinemaWrapperProps = {
    children: React.ReactNode;
};
//#endregion

//#region [Component]
export default function SelectedCinemaWrapper({ children }: SelectedCinemaWrapperProps) {
    const [selectedCinemas, dispatch] = useReducer(selectedCinemaReducer, null);
    const [availableCinemas, setAvailableCinemas] = useState<Cinema[]>([]);
    const [showSelectorDialog, setShowSelectorDialog] = useState(false);
    const locationContext = useLocationContext();
    const [isReady, setIsReady] = useState<boolean>(false);

    const { fiLoading, fiData } = useFilminfo(CINEMA_QUERY, { location: locationContext.location }, { active: !!locationContext.location });

    useEffect(() => {
        if( !locationContext.location && locationContext.isReady) {
            setIsReady(true);
        }
    }, [locationContext.location, locationContext.isReady]);

    useEffect(() => {
        if (!fiLoading && fiData) {
            if (fiData?.cinemaQuery?.getCinemas?.length) {
                setAvailableCinemas(fiData.cinemaQuery!.getCinemas as (Cinema[]));
            } else {
                setAvailableCinemas([]);
            }
            dispatch({ type: "replace", cinemas: null });
            setIsReady(true);
        }
    }, [fiData, fiLoading]);

    return <SelectedCinemaContext.Provider value={
        {
            selectedCinemas: selectedCinemas,
            setSelectedCinemas: (cinemas) => dispatch({ type: "replace", cinemas }),
            addSelectedCinema: (cinema) => dispatch({ type: "add", cinema }),
            removeSelectedCinema: (cinema) => dispatch({ type: "remove", cinema }),
            availableCinemas: availableCinemas,
            setAvailableCinemas,
            setShowSelectCinemaDialog: setShowSelectorDialog,
            showSelectCinemaDialog: showSelectorDialog,
            isReady: isReady

        }
    }>
        {children}
        {showSelectorDialog && <CinemaSelector />}
    </SelectedCinemaContext.Provider>;
}
//#endregion

//#region [Other]
type CinemaAction = CinemaAddAction | CinemaRemoveAction | CinemaReplaceAction;

type CinemaAddAction = {
    type: "add";
    cinema: string;
};

type CinemaRemoveAction = {
    type: "remove";
    cinema: string;
};

type CinemaReplaceAction = {
    type: "replace",
    cinemas: string[] | null;
};

function selectedCinemaReducer(state: string[] | null, action: CinemaAction) {
    let cinemas: string[] | null = state ?? [];
    switch (action.type) {
        case "add":
            if (!cinemas.includes(action.cinema)) {
                cinemas = [...cinemas, action.cinema]; // return a new object
            }
            break;
        case "remove":
            cinemas = cinemas.filter(cin => cin !== action.cinema); // new object
            break;
        case "replace":
            cinemas = action.cinemas;
            break;
    }
    if (cinemas?.length === 0) {
        cinemas = null;
    }
    return cinemas;
}
//#endregion

//#region [Other]
const CINEMA_QUERY = gql`query ($location: String) {
		cinemaQuery {
			getCinemas (location: $location) {
				name
				firmId
			}
		}
	}`;

//#endregion

//#region [Other]
export function useSelectedCinemaContext() {
    return useContext(SelectedCinemaContext);
}
//#endregion